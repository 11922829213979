<template>
  <div>
    <Header />
    <Content />
    <!-- <Footer /> -->
    <NewFooterVue />
  </div>
</template>

<script>
// Imported Components
import Header from "@/components/Header.vue";
import Content from "@/components/Content.vue";
// import Footer from '@/components/Footer.vue'
import NewFooterVue from "@/components/NewFooter.vue";

export default {
  name: "Home",
  components: {
    Header,
    Content,
    NewFooterVue,
  },
};
</script>

<style scoped></style>
